<template>
  <div class="row">
    <div class="col-12 col-lg-7 c g">
      <div class="card">
        <div class="card-header text-center">
          <h4 class="text-center" v-if="edit">
            <i class="fa fa-edit"></i>
            تعديل الطالب
          </h4>
          <h4 class="text-center" v-if="!edit">
            <i class="fa fa-user-plus"></i>
            اضافة طالب جديد
          </h4>
        </div>
        <div class="card-body">
          <div class="col-12 text-center">
            <img
              :src="
                image ? image : require('@/assets/images/avatars/avatar2.png')
              "
              style="
                width: 100px;
                height: 100px;
                cursor: pointer;
                object-fit: cover;
                border-radius: 50%;
              "
            />
            <div id="progress-wrp">
              <div class="progress-bar"></div>
              <div class="status">0%</div>
            </div>
            <br />
            <input
              type="file"
              title="اضغط لتغيير الصورة"
              id="ingredient_file"
            />
          </div>
          <div class="form-group">
            <h5 for="">الإسم <span class="text-danger">*</span></h5>
            <input type="text" class="form-control" v-model="student.name" />
          </div>
          <div class="form-group">
            <h5 for="">رقم الهوية <span class="text-danger">*</span></h5>
            <input type="text" class="form-control" v-model="student.number" />
          </div>
          <div class="form-group">
            <h5 for="">
              ولي الأمر <span class="text-danger">*</span>
              <a href="/parents/add-or-edit" target="_blank"><u>إضافة ولي أمر</u></a>
            </h5>
            <v-select
              v-model="student.parent_id"
              v-on:input="phonea()"
              :options="parents"
              :dir="'rtl'"
              label="name"
            />
          </div>
          <div class="form-group">
            <h5 for="">الجوال</h5>
            <input type="text" class="form-control" v-model="student.phone" />
          </div>
          <div class="form-group">
            <h5 for="">جوال آخر للضرورة</h5>
            <input
              type="text"
              class="form-control"
              v-model="student.another_phone"
            />
          </div>
          <div class="form-group">
            <h5 for="">
              الصف <span class="text-danger">*</span>
              <button class="btn btn-sm btn-link" @click="manual = !manual">
                كتابة يدوياً
              </button>
            </h5>
            <select
              class="form-control"
              name=""
              v-if="!manual"
              v-model="student.classname"
              id=""
            >
              <option :value="undefined">-- إختر --</option>
              <template v-for="classname in classes">
                <option :value="classname" :key="classname">
                  {{ classname }}
                </option>
              </template>
            </select>
            <input
              type="text"
              class="form-control"
              v-if="manual"
              v-model="student.classname"
              name=""
              id=""
            />
          </div>
          <div class="form-group">
            <h5 for="">الفصل <span class="text-danger">*</span></h5>
            <select
              class="form-control"
              name=""
              v-if="!manual"
              v-model="student.classroom"
              id=""
            >
              <option :value="undefined">-- إختر --</option>
              <template v-for="classroom in classrooms">
                <option :value="classroom" :key="classroom">
                  {{ classroom }}
                </option>
              </template>
            </select>
            <input
              type="text"
              class="form-control"
              v-if="manual"
              v-model="student.classroom"
              name=""
              id=""
            />
          </div>
          <div class="form-group">
            <h5 for="">
              خط السير <span class="text-danger">*</span> &nbsp;
              <a href="javascript:;" @click="viewbackgroup = true">
                <small>تعيين خط سير مختلف للعودة </small></a
              >
            </h5>
            <select
              class="form-control"
              name=""
              v-model="student.group_id"
              id=""
            >
              <option :value="undefined">-- إختر --</option>
              <template v-for="group in groups">
                <option :value="group._id" :key="group._id">
                  {{ group.title }}
                </option>
              </template>
            </select>
          </div>

          <div v-if="viewbackgroup">
            <div class="form-group">
              <h5 for="">خط السير العودة</h5>
              <select
                class="form-control"
                name=""
                v-model="student.group_back_id"
                id=""
              >
                <option :value="undefined">-- إختر --</option>
                <template v-for="group in groups">
                  <option :value="group._id" :key="group._id">
                    {{ group.title }}
                  </option>
                </template>
              </select>
            </div>
          </div>
          <div class="form-group">
            <h5 for="">القسم <span class="text-danger">*</span></h5>
            <select
              class="form-control"
              name=""
              v-model="student.section_id"
              id=""
            >
              <template v-for="section in sections">
                <option :value="section._id" :key="section._id">
                  {{ section.title }}
                </option>
              </template>
            </select>
          </div>
          <div class="form-group">
            <h5 for="">نوع الطالب</h5>
            <select class="form-control" name="" v-model="student.type" id="">
              <option value="both">ذهاب وعودة</option>
              <option value="go">ذهاب</option>
              <option value="back">عودة</option>
            </select>
          </div>
          <div class="form-group">
            <h5 for="">ملاحظات</h5>
            <textarea
              class="form-control"
              name=""
              id=""
              v-model="student.notes"
              rows="3"
            ></textarea>
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-secondary" v-if="loading">
              <i class="fa fa-clock"></i> جاري التحميل...
            </button>
            <button class="btn btn-primary" v-if="!loading" @click="done()">
              <span v-if="!edit"> اضافة الآن </span>
              <span v-if="edit"> تعديل الآن </span>
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 c g">
      <div class="card card-body">
        <div class="col-12" v-if="viewmap">
          <l-map :zoom="zoom" @click="addMarker" :center="[mylat, mylong]">
            <l-tile-layer :url="url" />
            <l-marker v-if="location" :lat-lng="[location.lat, location.long]">
              <l-icon>
                <b-img
                  style="
                    border: dashed 2px red;
                    border-radius: 50%;
                    padding: 5px;
                    object-fit: cover;
                  "
                  :src="
                    image
                      ? image
                      : require('@/assets/images/avatars/avatar2.png')
                  "
                  height="50"
                  width="50"
                />
              </l-icon>
            </l-marker>
          </l-map>
        </div>
        <button class="btn btn-sm btn-link" @click="viewmap = true">
          <i class="fa fa-map"></i> فتح الخريطة
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
delete Icon.Default.prototype._getIconUrl;
import { LMap, LTileLayer, LMarker, LIcon, LPolyline } from "vue2-leaflet";
import { latLng, icon, Icon } from "leaflet";
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import { BImg } from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
    BImg,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPolyline,
  },
  data() {
    return {
      viewbackgroup: false,
      loading: false,
      user: JSON.parse(localStorage.getItem("user")),
      student: {},
      edit: false,
      parents: [],
      sections: [],
      groups: [],
      manual: false,
      classes: [],
      classrooms: [],
      image: null,
      pasteinterval: null,
      viewmap: false,
      location: null,
      zoom: 13,
      zoom2: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      ppoint: null,
      points: [],
      polyline: {
        latlngs: [],
        latlngs2: [],
        color: "green",
        color2: "red",
      },
      icon: icon({
        iconUrl: require("@/assets/images/bus.png"),
        iconSize: [50, 50],
        iconAnchor: [16, 37],
      }),
      ///////
      mylat: null,
      mylong: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.pasteinterval);
  },
  created() {
    if (!checkPer("students-add")) {
      this.$router.push("/per");
      return false;
    }
    var g = this;
    var last_point = null;
    g.pasteinterval = setInterval(function () {
      navigator.clipboard.readText().then((text) => {
        if (text.includes(",") && text.includes(".") && !text.includes("/")) {
          if (text != last_point) {
            g.addPoint(text);
            last_point = text;
          }
        }
      });
    }, 500);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (r) {
        g.mylat = r.coords.latitude;
        g.mylong = r.coords.longitude;
      });
    } else {
      alert("متصفحك لا يدعم الخرائط");
    }
    var Upload = function (file) {
      this.file = file;
    };

    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", this.file, this.getName());
      formData.append("jwt", g.user.jwt);
      $.ajax({
        type: "POST",
        url: api + "/user/general/upload",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          $("#progress-wrp").fadeOut("slow");
          g.student.image = data.response;
          g.image = data.response;
        },
        error: function (error) {
          // handle error
          alert("حدث خطأ");
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    };

    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    }, 1000);
    $.post(api + "/user/parents/list-names", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.parents = r.response;
        if (window.location.href.includes("#")) {
          g.edit = true;
          $.post(api + "/user/students/student", {
            jwt: g.user.jwt,
            id: window.location.hash.split("#")[1],
          })
            .then(function (r) {
              g.student = r.response;
              g.image = g.student.image;
              if (g.student.group_back_id != "--" && g.student.group_back_id) {
                g.viewbackgroup = true;
              }
              g.location = g.student.location;
              g.parents.forEach((element) => {
                if (element._id == g.student.parent_id) {
                  g.student.parent_id = element;
                }
              });
            })
            .catch(function () {
              alert("حدث خطأ");
            });
        }
      })
      .catch(function () {
        alert("حدث خطأ");
      });
    $.post(api + "/user/sections/list", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.sections = r.response;
        if (!window.location.href.includes("#")) {
          g.student.section_id = g.sections[0]._id;
        }
        setTimeout(() => {
          g.viewmap = true;
        }, 1000);
      })
      .catch(function () {
        alert("حدث خطأ2");
      });
    $.post(api + "/user/groups/list", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = r.response;
      })
      .catch(function () {
        alert("حدث خطأ2");
      });
    $.post(api + "/user/students/classes", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.classes = r.response.classes;
        g.classrooms = r.response.classrooms;
      })
      .catch(function () {
        alert("حدث خطأ2");
      });
  },
  methods: {
    checkPer(e) {
      return checkPer(e);
    },
    addMarker(event) {
      this.addPoint(event.latlng.lat + "," + event.latlng.lng);
      //this.markers.push(event.latlng);
    },
    addPoint(point) {
      if (confirm("هل تريد اضافة الاحداثيات؟")) {
        var g = this;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "تم لصق الاحداثيات",
            icon: "CopyIcon",
            variant: "info",
          },
        });
        var audio = new Audio(
          "https://cdn-static.brmja.com/storage/uploads/files/620511d8d5536534353593.mp3"
        );
        audio.play();
        g.student.location = {
          lat: point.split(",")[0].trim(),
          long: point.split(",")[1].trim(),
        };
        g.location = {
          lat: point.split(",")[0].trim(),
          long: point.split(",")[1].trim(),
        };
      }
    },
    done() {
      var g = this;
      $.post(api + "/user/students/add-or-edit", {
        jwt: g.user.jwt,
        student: g.student,
        edit: g.edit,
      })
        .then(function (r) {
          if (!g.edit) {
            g.viewbackgroup = false;
          }
          if (r.status == 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم الحفظ",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: r.response,
                icon: "XIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function () {
          alert("حدث خطأ");
        });
    },
  },
};
</script>
<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.5/leaflet.css";
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style><style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>